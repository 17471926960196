<template>
  <div class="register-page">
    <div class="top">
      <div class="description">
        <div class="hello">你好，</div>
        <div class="content">
          <span class="text">您的账号未设置密码</span>
          <span class="text">请设置登录密码</span>
        </div>
      </div>
      <div class="form-container">
        <van-form
          key="registerForm"
          class="register-form"
          ref="registerForm"
          :show-error="false"
        >
          <van-field
            v-model="registerModel.password"
            name="password"
            placeholder="请输入8-16位数字、字母组合密码"
            :type="showPassword ? 'text' : 'password'"
            ref="password"
            clearable
            :show-error="false"
            :error="false"
            :rules="rules.password"
            @focus="onFocus"
          >
            <template #button>
              <SvgIcon
                type="eye_open"
                class="icon"
                v-show="showPassword"
                @click="handleChangeShowPassword"
              />
              <SvgIcon
                type="eye_close"
                class="icon"
                v-show="!showPassword"
                @click="handleChangeShowPassword"
              />
            </template>
          </van-field>
          <van-field
            v-model="registerModel.confirmPassword"
            name="confirmPassword"
            placeholder="重复输入登录密码"
            :type="showConfirmPassword ? 'text' : 'password'"
            ref="confirmPassword"
            clearable
            :show-error="false"
            :error="false"
            :rules="rules.confirmPassword"
            @focus="onFocus"
          >
            <template #button>
              <SvgIcon
                type="eye_open"
                class="icon"
                v-show="showConfirmPassword"
                @click="handleChangeShowConfirmPassword"
              />
              <SvgIcon
                type="eye_close"
                class="icon"
                v-show="!showConfirmPassword"
                @click="handleChangeShowConfirmPassword"
              />
            </template>
          </van-field>
        </van-form>
        <div class="skip" @click="skip">
          以后在 [我的] - [设置登录密码] 中设置，暂时跳过
        </div>
        <!-- <div class="agreement-list">
          点击注册代表您已阅读并同意<a
            class="link agreement-link"
            v-for="item in agreementList"
            :key="item.title"
            @click="handleShowAgreement(item.title)"
            >《{{ item.title }}》</a
          >
        </div> -->
      </div>
    </div>
    <div class="bottom" v-if="!isPopup">
      <div class="button-container">
        <van-button
          round
          type="primary"
          @click="handleSetPsw"
          :loading="buttonLoading"
          >设置完成</van-button
        >
      </div>
    </div>
    <Agreement v-model="showAgreement" :title="curAgreementTitle" />
  </div>
</template>

<script>
  import { validateMobileNumber, validatePassword } from '@/utils/validate'
  import passportApi from '@/api/passport'
  import myApi from '@/api/my'
  import Agreement from '@/components/Agreement/index.vue'

  export default {
    name: 'setPassword',
    components: { Agreement },
    props: {
      isPopup: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      this.agreementList = [
        {
          title: '服务协议',
        },
        {
          title: '隐私协议',
        },
        {
          title: '证书服务协议',
        },
      ]
      return {
        redirect: undefined,
        otherQuery: undefined,
        // 展示协议页面
        showAgreement: false,
        curAgreementTitle: '服务协议',
        rules: {
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'onBlur',
            },
            {
              validator: this.validatePassword,
              trigger: 'onBlur',
              message: '密码为8-16位的数字、字母组合',
            },
          ],
          confirmPassword: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'onBlur',
            },
            {
              validator: this.validatePassword,
              trigger: 'onBlur',
              message: '密码为8-16位的数字、字母组合',
            },
            {
              validator: this.validateConfirmPassword,
              trigger: 'onBlur',
              message: '两次密码输入不相同',
            },
          ],
        },
        registerModel: {
          password: undefined,
          confirmPassword: undefined,
        },
        // 是否密码展示为明文
        showPassword: false,
        showConfirmPassword: false,
        // isCheckError: false,
        buttonLoading: false, // 注册按钮的loading
      }
    },
    filters: {},
    created() {
      const { query } = this.$route
      if (query) {
        this.redirect = query.redirect
      }
    },
    methods: {
      async skip() {
        try {
          await passportApi.skipSetPassword()
          await this.$store.dispatch('fetchUserInfo')
          await this.$router.replace({
            path: this.redirect || '/',
            query: this.otherQuery,
          })
        } catch (error) {
          console.error(error)
        }
      },
      onFocus() {
        setTimeout(() => {
          this.$refs.password.$el.scrollIntoView()
        }, 1000)
      },
      // 验证密码
      validatePassword(val) {
        return validatePassword(val)
      },
      // 确认密码
      validateConfirmPassword(val) {
        return val === this.registerModel.password
      },

      handleShowAgreement(title) {
        this.showAgreement = true
        this.curAgreementTitle = title
      },

      // 验证表单项
      async validateField(formName, name) {
        let isPassed = true
        try {
          await this.resetValidation(formName, name)
          await this.$refs[formName].validate(name)
        } catch (error) {
          isPassed = false
          console.error(error)
        }
        return isPassed
      },

      // 重置表单项的验证提示
      async resetValidation(formName, name) {
        try {
          await this.$refs[formName].resetValidation(name)
        } catch (error) {
          console.error(error)
        }
      },

      // 切换密码是否为明文
      handleChangeShowPassword() {
        this.showPassword = !this.showPassword
      },
      handleChangeShowConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword
      },

      async handleSetPsw() {
        try {
          this.buttonLoading = true
          await this.$refs.registerForm.validate()
          await myApi.setLoginPwd(this.registerModel)
          await this.$store.dispatch('fetchUserInfo')
          await this.$router.replace({
            path: this.redirect || '/',
            query: this.otherQuery,
          })
          // const isPassed = this.validateField('registerForm', 'password')
          // if (isPassed) {
          //   await myApi.setLoginPwd(this.registerModel)
          //   await this.$store.dispatch('fetchUserInfo')
          //   await this.$router.replace({
          //     path: this.redirect || '/',
          //     query: this.otherQuery,
          //   })
          // }
        } catch (error) {
          console.error(error)
        } finally {
          this.buttonLoading = false
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .register-page {
    width: 100%;
    max-height: 100vh;
    padding: 63px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .description {
      font-weight: 400;
      margin-bottom: 43px;

      .hello {
        font-size: 36px;
        text-align: left;
        color: @TEXT-COLOR-0;
      }

      .content {
        margin-top: 21px;
        font-size: 18px;
        color: @TEXT-COLOR-1;
        display: flex;
        flex-direction: column;
        line-height: 30px;
      }
    }

    .form-container {
      flex: 1;
      .register-form {
        .get-code {
          font-size: 16px;
          color: @BLUE;

          &.disabled {
            color: @TEXT-COLOR-2;
          }
        }
      }
      .skip {
        margin-top: 16px;
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
        color: #677283;
      }
      .agreement-list {
        margin-top: 8px;
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
        word-break: break-all;
      }
    }

    .button-container {
      width: 100%;

      /deep/ .van-button {
        width: 100%;

        .van-button__text {
          color: @WHITE;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  /deep/ .van-form {
    .van-cell {
      margin-top: 15px;
      padding: 0;
      font-size: 16px;
      color: @TEXT-COLOR-0;

      &.van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: @TEXT-COLOR-0;
        // font-size: 16px;
      }

      &::after {
        display: none;
      }

      .van-field__body {
        border-bottom: 1px solid @BORDER-COLOR-0;
        padding: 14px 0;
      }

      .van-field__error-message {
        font-size: 12px;
        color: @RED;
      }
    }
  }
</style>
